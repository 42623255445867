<template>
  <div class="custom-actions">
    <b-button
      class="m-1"
      variant="success"
      size="sm"
      title="Edit Filter"
      @click="itemAction('edit-item', rowData, rowIndex)">
      <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
    </b-button>

    <b-button
      class="m-1"
      variant="danger"
      size="sm"
      title="Delete Filter"
      @click="itemAction('delete-item', rowData, rowIndex)">
      <b-icon icon="trash" aria-hidden="true"></b-icon>
    </b-button>
  </div>
</template>
<script>
  const EditFilter = () => import('./EditFilter.vue');
  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    methods : {
      itemAction(action, data, index) {
        if (action === 'delete-item') {
          this.$modal.show('dialog', {
            title : 'Please confirm:',
            text  : `Are you sure you want to delete filter ` +
              `<b>${data.name}</b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$events.fire('loading-state', true);
                this.$http.delete('api/filter', {
                  data : {
                    'mFilterId' : data.mobileFilterId,
                  },
                }).then(() => {
                  this.closeDialog();
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted category successfully.',
                  });
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                }).finally(() => {
                  this.$events.fire('loading-state', false);
                });
              },
            }, {
              title : 'No',
            } ],
          });
        } else if (action === 'edit-item'){
          this.$modal.show(EditFilter, {
            data : data,
          }, {
            height   : 'auto',
            adaptive : true,
          }, {
            'reload-table' : (event) => {
              this.$parent.reload();
            },
          });
        }
      },

      /**
       * Close Dialog
       */
      closeDialog() {
        this.$parent.reload();
        this.$modal.hide('dialog');
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>